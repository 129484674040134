(function($) {
    /**
     * Serbian Latin language package
     * Translated by @markocrni
     */
    FormValidation.I18n = $.extend(true, FormValidation.I18n, {
        'sr_RS': {
            base64: {
                'default': 'Prosimo vnesite base 64 kodirano vrednost'
            },
            between: {
                'default': 'Prosimo vnesite vrednost med %s in %s',
                notInclusive: 'Prosimo vnesite vrednost točno med %s in %s'
            },
            bic: {
                'default': 'Prosimo vnesite veljavno BIC številko'
            },
            callback: {
                'default': 'Prosimo vnesite veljavno vrednost'
            },
            choice: {
                'default': 'Prosimo vnesite veljavno vrednost',
                less: 'Prosimo izberite najmanj %s opcij(o)',
                more: 'Prosimo izberite največ %s opcij(o)',
                between: 'Prosimo izberite opcije %s - %s'
            },
            color: {
                'default': 'Prosimo vnesite veljavno barvo'
            },
            creditCard: {
                'default': 'Prosimo vnesite veljavno številko kreditne kartice'
            },
            cusip: {
                'default': 'Prosimo vnesite veljavno CUSIP številko'
            },
            cvv: {
                'default': 'Prosimo vnesite veljavno CVV številko'
            },
            date: {
                'default': 'Prosimo vnesite veljaven datum',
                min: 'Prosimo vnesite datum po %s',
                max: 'Prosimo vnesite datum pred %s',
                range: 'Prosimo vnesite datum od %s do %s'
            },
            different: {
                'default': 'Prosimo vnesite drugo vrednost'
            },
            digits: {
                'default': 'Prosimo vnesite samo cifre'
            },
            ean: {
                'default': 'Prosimo vnesite veljavno EAN številko'
            },
            ein: {
                'default': 'Prosimo vnesite veljavno EIN številko'
            },
            emailAddress: {
                'default': 'Prosimo vnesite veljaven e-mail naslov'
            },
            file: {
                'default': 'Prosimo vnesite veljavno datoteko'
            },
            greaterThan: {
                'default': 'Prosimo vnesite vrednost večjo ali enako od %s',
                notInclusive: 'Prosimo vnesite vrednost večjo od %s'
            },
            grid: {
                'default': 'Prosimo vnesite veljavno GRId številko'
            },
            hex: {
                'default': 'Prosimo vnesite veljavno heksadecimalno številko'
            },
            iban: {
                'default': 'Prosimo vnesite veljavno IBAN številko',
                country: 'Prosimo vnesite veljavno IBAN številko %s',
                countries: {
                    AD: 'Andore',
                    AE: 'Združenih arabskih emiratov',
                    AL: 'Albanije',
                    AO: 'Angole',
                    AT: 'Avstrije',
                    AZ: 'Azerbejdžana',
                    BA: 'Bosne in Hercegovine',
                    BE: 'Belgije',
                    BF: 'Burkina Fase',
                    BG: 'Bolgarije',
                    BH: 'Bahraina',
                    BI: 'Burundija',
                    BJ: 'Benina',
                    BR: 'Brazilije',
                    CH: 'Švice',
                    CI: 'Slonokoščene obale',
                    CM: 'Kameruna',
                    CR: 'Kostarike',
                    CV: 'Zelenortskih otokov',
                    CY: 'Cipra',
                    CZ: 'Češke',
                    DE: 'Nemčije',
                    DK: 'Danske',
                    DO: 'Dominikanske republike',
                    DZ: 'Alžirije',
                    EE: 'Estonije',
                    ES: 'Španije',
                    FI: 'Finske',
                    FO: 'Farskih otokov',
                    FR: 'Francije',
                    GB: 'Velike Britanije',
                    GE: 'Gruzije',
                    GI: 'Giblartarja',
                    GL: 'Grenlandije',
                    GR: 'Grčije',
                    GT: 'Gvatemale',
                    HR: 'Hrvaške',
                    HU: 'Madžarske',
                    IE: 'Irske',
                    IL: 'Izraela',
                    IR: 'Irana',
                    IS: 'Islandije',
                    IT: 'Italije',
                    JO: 'Jordanije',
                    KW: 'Kuvajta',
                    KZ: 'Kazahstana',
                    LB: 'Libanona',
                    LI: 'Lihtenštajna',
                    LT: 'Litvanije',
                    LU: 'Luksemburga',
                    LV: 'Latvije',
                    MC: 'Monaka',
                    MD: 'Moldavije',
                    ME: 'Črne Gore',
                    MG: 'Madagaskarja',
                    MK: 'Makedonije',
                    ML: 'Malija',
                    MR: 'Mavretanije',
                    MT: 'Malte',
                    MU: 'Mavricijusa',
                    MZ: 'Mozambika',
                    NL: 'Nizozemske',
                    NO: 'Norveške',
                    PK: 'Pakistana',
                    PL: 'Poljske',
                    PS: 'Palestine',
                    PT: 'Portugalske',
                    QA: 'Katarja',
                    RO: 'Romunije',
                    RS: 'Srbije',
                    SA: 'Savdske Arabije',
                    SE: 'Švedske',
                    SI: 'Slovenije',
                    SK: 'Slovaške',
                    SM: 'San Marina',
                    SN: 'Senegala',
                    TN: 'Tunizije',
                    TR: 'Turčije',
                    VG: 'Britanskih Deviških otokov'
                }
            },
            id: {
                'default': 'Prosimo vnesite veljavno identifikacijsko številko',
                country: 'Prosimo vnesite veljavno identifikacijsko številko %s',
                countries: {
                    BA: 'Bosne in Hercegovine',
                    BG: 'Bolgarije',
                    BR: 'Brazilije',
                    CH: 'Švice',
                    CL: 'Čila',
                    CN: 'Kitajske',
                    CZ: 'Češke',
                    DK: 'Danske',
                    EE: 'Estonije',
                    ES: 'Španije',
                    FI: 'Finske',
                    HR: 'Hrvaške',
                    IE: 'Irske',
                    IS: 'Islandije',
                    LT: 'Litvanije',
                    LV: 'Letonije',
                    ME: 'Črne Gore',
                    MK: 'Makedonije',
                    NL: 'Nizozemske',
                    RO: 'Romunije',
                    RS: 'Srbije',
                    SE: 'Švedske',
                    SI: 'Slovenije',
                    SK: 'Slovaške',
                    SM: 'San Marina',
                    TH: 'Tajske',
                    ZA: 'Južne Afrike'
                }
            },
            identical: {
                'default': 'Prosimo vnesite isto vrednost'
            },
            imei: {
                'default': 'Prosimo vnesite veljavno IMEI številko'
            },
            imo: {
                'default': 'Prosimo vnesite veljavno IMO številko'
            },
            integer: {
                'default': 'Prosimo vnesite veljavno številko'
            },
            ip: {
                'default': 'Prosimo vnesite veljaven IP naslov',
                ipv4: 'Prosimo vnesite veljaven IPv4 naslov',
                ipv6: 'Prosimo vnesite veljaven IPv6 naslov'
            },
            isbn: {
                'default': 'Prosimo vnesite veljavno ISBN številko'
            },
            isin: {
                'default': 'Prosimo vnesite veljavno ISIN številko'
            },
            ismn: {
                'default': 'Prosimo vnesite veljavno ISMN številko'
            },
            issn: {
                'default': 'Prosimo vnesite veljavno ISSN številko'
            },
            lessThan: {
                'default': 'Molimo da unesete vrednost manju ili jednaku od %s',
                notInclusive: 'Molimo da unesete vrednost manju od %s'
            },
            mac: {
                'default': 'Molimo da unesete ispravnu MAC adresu'
            },
            meid: {
                'default': 'Molimo da unesete ispravan MEID broj'
            },
            notEmpty: {
                'default': 'Molimo da unesete vrednost'
            },
            numeric: {
                'default': 'Molimo da unesete ispravan decimalni broj'
            },
            phone: {
                'default': 'Molimo da unesete ispravan broj telefona',
                country: 'Molimo da unesete ispravan broj telefona %s',
                countries: {
                    AE: 'Ujedinjenih Arapskih Emirata',
                    BR: 'Brazila',
                    CN: 'Kine',
                    CZ: 'Češke',
                    DE: 'Nemačke',
                    DK: 'Danske',
                    ES: 'Španije',
                    FR: 'Francuske',
                    GB: 'Engleske',
                    IN: 'Индија',
                    MA: 'Maroka',
                    PK: 'Pakistana',
                    RO: 'Rumunije',
                    RU: 'Rusije',
                    SK: 'Slovačke',
                    TH: 'Tajlanda',
                    US: 'Amerike',
                    VE: 'Venecuele'
                }
            },
            regexp: {
                'default': 'Molimo da unesete vrednost koja se poklapa sa paternom'
            },
            remote: {
                'default': 'Molimo da unesete ispravnu vrednost'
            },
            rtn: {
                'default': 'Molimo da unesete ispravan RTN broj'
            },
            sedol: {
                'default': 'Molimo da unesete ispravan SEDOL broj'
            },
            siren: {
                'default': 'Molimo da unesete ispravan SIREN broj'
            },
            siret: {
                'default': 'Molimo da unesete ispravan SIRET broj'
            },
            step: {
                'default': 'Molimo da unesete ispravan korak od %s'
            },
            stringCase: {
                'default': 'Molimo da unesete samo mala slova',
                upper: 'Molimo da unesete samo velika slova'
            },
            stringLength: {
                'default': 'Molimo da unesete vrednost sa ispravnom dužinom',
                less: 'Molimo da unesete manje od %s karaktera',
                more: 'Molimo da unesete više od %s karaktera',
                between: 'Molimo da unesete vrednost dužine između %s i %s karaktera'
            },
            uri: {
                'default': 'Molimo da unesete ispravan URI'
            },
            uuid: {
                'default': 'Molimo da unesete ispravan UUID broj',
                version: 'Molimo da unesete ispravnu verziju UUID %s broja'
            },
            vat: {
                'default': 'Molimo da unesete ispravan VAT broj',
                country: 'Molimo da unesete ispravan VAT broj %s',
                countries: {
                    AT: 'Austrije',
                    BE: 'Belgije',
                    BG: 'Bugarske',
                    BR: 'Brazila',
                    CH: 'Švajcarske',
                    CY: 'Kipra',
                    CZ: 'Češke',
                    DE: 'Nemačke',
                    DK: 'Danske',
                    EE: 'Estonije',
                    ES: 'Španije',
                    FI: 'Finske',
                    FR: 'Francuske',
                    GB: 'Engleske',
                    GR: 'Grčke',
                    EL: 'Grčke',
                    HU: 'Mađarske',
                    HR: 'Hrvatske',
                    IE: 'Irske',
                    IS: 'Islanda',
                    IT: 'Italije',
                    LT: 'Litvanije',
                    LU: 'Luksemburga',
                    LV: 'Letonije',
                    MT: 'Malte',
                    NL: 'Holandije',
                    NO: 'Norveške',
                    PL: 'Poljske',
                    PT: 'Portugala',
                    RO: 'Romunje',
                    RU: 'Rusije',
                    RS: 'Srbije',
                    SE: 'Švedske',
                    SI: 'Slovenije',
                    SK: 'Slovačke',
                    VE: 'Venecuele',
                    ZA: 'Južne Afrike'
                }
            },
            vin: {
                'default': 'Molimo da unesete ispravan VIN broj'
            },
            zipCode: {
                'default': 'Molimo da unesete ispravan poštanski broj',
                country: 'Molimo da unesete ispravan poštanski broj %s',
                countries: {
                    AT: 'Austrije',
                    BR: 'Brazila',
                    CA: 'Kanade',
                    CH: 'Švajcarske',
                    CZ: 'Češke',
                    DE: 'Nemačke',
                    DK: 'Danske',
                    ES: 'Španije',
                    FR: 'Francuske',
                    GB: 'Engleske',
                    IE: 'Irske',
                    IN: 'Индија',
                    IT: 'Italije',
                    MA: 'Maroka',
                    NL: 'Holandije',
                    PT: 'Portugala',
                    RO: 'Rumunije',
                    RU: 'Rusije',
                    SE: 'Švedske',
                    SG: 'Singapura',
                    SK: 'Slovačke',
                    US: 'Amerike'
                }
            }
        }
    });
}(jQuery));
