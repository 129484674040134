var Form = {
  formdata: {},
  storeURL: "/formtest",
  storage: null,
  username: "tablica",

  run: function () {
    Form.storage = $.localStorage;

    $("#_form_47_")
      .formValidation({
        framework: "bootstrap",
        live: "submitted",
        icon: {
          valid: null,
          invalid: null,
          validating: null,
        },
        fields: {
          name: {
            validators: {
              notEmpty: {
                message: "Vpišite ime",
              },
            },
          },
          surname: {
            validators: {
              notEmpty: {
                message: "Vpišite priimek",
              },
            },
          },
          phone: {
            validators: {
              notEmpty: {
                message: "Vpišite telefonsko številko",
              },
            },
          },
          email: {
            validators: {
              notEmpty: {
                message: "Vpišite elektronsko pošto",
              },
              regexp: {
                regexp: "^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$",
                message: "Vpišite pravilno elektronsko pošto",
              },
            },
          },
          kraj: {
            selector: "#input-town",
            validators: {
              notEmpty: {
                message: "Vpišite kraj",
              },
            },
          },
          naslov: {
            selector: "#input-address",
            validators: {
              notEmpty: {
                message: "Vpišite vaš naslov",
              },
            },
          },
          zip: {
            selector: "#input-zip",
            validators: {
              notEmpty: {
                message: "Vpišite poštno številko",
              },
            },
          },
          naziv: {
            selector: "#input-naziv",
            validators: {
              notEmpty: {
                message: "Izberite naziv",
              },
            },
          },
          carswitch: {
            selector: "#input-carswitch",
            validators: {
              notEmpty: {
                message: "Izberite predviden datum zamenjave vozila",
              },
            },
          },
          contactme: {
            selector: "#contact_me",
            validators: {
              notEmpty: {
                message: "Polje je obvezno",
              },
            },
          },
        },
      })
      .on("err.field.fv", function (e, data) {
        if (data.fv.getSubmitButton()) {
          data.fv.disableSubmitButtons(false);
        }
      })
      .on("success.field.fv", function (e, data) {
        if (data.fv.getSubmitButton()) {
          data.fv.disableSubmitButtons(false);
        }
      })
      .on("success.form.fv", function () {
        // var interested_in = Array();
        // $.each($("input[name=interested_in]:checked"), function () {
        //   interested_in.push($(this).val());
        // });

        var ajaxdata = {
          name: $("#input-name").val(),
          surname: $("#input-surname").val(),
          phone: $("#input-phone").val(),
          email: $("#input-email").val(),
          choosen_car: $("input[name='field[21]']").val(),
          choosen_koncesionar: $("input[name='field[22]']").val(),
          gender: $("select[name='gender']").val(),
          address: $("#input-address").val(),
          zip: $("#input-zip").val(),
          town: $("#input-town").val(),
          wish_date: $("input[name='input-date']").val(),
          wish_hour: $("input[name='input-hour']").val(),
          message: $("textarea[name='input-message']").val(),
          car_switch: $("select[name='input-carswitch']").val(),
          company: $("#input-podjetje").val(),
          contact_me: $("#contact_me").is(":checked") ? 1 : 0,
          newsletter: $("#newsletter").is(":checked") ? 1 : 0,
          contact_by_email: $("#ch_email").is(":checked") ? 1 : 0,
          contact_by_sms: $("#ch_sms").is(":checked") ? 1 : 0,
          contact_by_phone: $("#ch_phone").is(":checked") ? 1 : 0,
          contact_by_mail: $("#ch_mail").is(":checked") ? 1 : 0,
          client_id: $("#client_id").val(),
        };

        window._load_script = function (url, callback) {
          var head = document.querySelector("head"),
            script = document.createElement("script"),
            r = false;
          script.type = "text/javascript";
          script.charset = "utf-8";
          script.src = url;
          if (callback) {
            script.onload = script.onreadystatechange = function () {
              if (!r && (!this.readyState || this.readyState == "complete")) {
                r = true;
                callback();
              }
            };
          }
          head.appendChild(script);
        };

        /*Insert into ActiveCampaign*/
        var serialized2 = "u=62&f=62&s=&c=0&m=0&act=sub&v=2&or-d517983c239bf8f823934fe295858ceb&field[44]=" + ($("select[name='gender']").val()=="") + "&firstname=" + $("#input-name").val() + "&lastname=" + $("#input-surname").val() + "&email=" + $("#input-email").val() + "&phone=+" + $("#input-phone").val() + "&field[1]=" + $("#input-phone").val() + "&field[29]=" + $("#input-address").val() + "&field[5]=" + $("#input-zip").val() + "&field[4]=" + $("#input-town").val() + "&field[165]=" + $("input[name='field[21]']").val() + "&field[152][date]=" + $("input[name='input-date']").val() + "&field[152][time]=" + $("input[name='input-hour']").val() + "&field[169]=" + $("input[name='field[22]']").val() + "&field[123]=" + $("textarea[name='input-message']").val() + "&field[125]=" + $("select[name='input-carswitch']").val() + "&field[180]=" + ($("#newsletter").is(":checked") ? "Da" : "Ne") + "&field[86]=" + ($("#contact_me").is(":checked") ? "Da" : "Ne") + "&field[82]=" + ($("#ch_email").is(":checked") ? "Da" : "Ne") + "&field[84]=" + ($("#ch_phone").is(":checked") ? "Da" : "Ne") + "&field[85]=" + ($("#ch_sms").is(":checked") ? "Da" : "Ne") + "&field[83]=" + ($("#ch_mail").is(":checked") ? "Da" : "Ne");                                                                                                             
        serialized2.replace(" ", "%20");
        serialized2.replace("č", "%C4%8D");
        serialized2.replace("Č", "%C4%8C");
        serialized2.replace("ć", "%C4%87");
        serialized2.replace("Ć", "%C4%86");
        serialized2.replace("š", "%C5%A1");
        serialized2.replace("Š", "%C5%A0");
        serialized2.replace("ž", "%C5%BE");
        serialized2.replace("Ž", "%C5%BD");
        serialized2.replace("ë", "%C3%AB");
        serialized2.replace("Ë", "%C3%8B");
        serialized2.replace(",", "%2C");
        serialized2.replace(",", "%2B");

        serialized2.replace("field[44]=m", "field[44]=Mo%C5%A1ki");
        serialized2.replace("field[44]=z", "field[44]=%C5%BDenski");
        serialized2.replace("field[44]=p", "field[44]=Samostojni podjetnik");

        _load_script('https://citroen.activehosted.com/proc.php?' + serialized2 + '&jsonp=true');

        /*         if (!$("#ch_email").length) {
          ajaxdata["contact_by_email"] = "Ne";
        }
        if (!$("#ch_sms").length) {
          ajaxdata["contact_by_sms"] = "Ne";
        }
        if (!$("#ch_phone").length) {
          ajaxdata["contact_by_phone"] = "Ne";
        } */

        if (!$("#newsletter").length) {
          ajaxdata["newsletter"] = 1;
        }

        // var madata = {
        //   u: "47",
        //   f: "47",
        //   s: "",
        //   c: "0",
        //   m: "0",
        //   act: "sub",
        //   v: "2",
        //   firstname: $("#input-name").val(),
        //   lastname: $("#input-surname").val(),
        //   email: $("#input-email").val(),
        //   "field[56]": $("#input-phone").val(),
        //   "field[120]": $("#input-podjetje").val(),
        //   "field[6]": $("#input-address").val(),
        //   "field[5]": $("#input-zip").val(),
        //   "field[4]": $("#input-town").val(),
        //   "field[21]": $("input[name='field[21]']").val(),
        //   "field[42]": $("#newsletter").is(":checked") ? "Da" : "Ne", //obveščanje po telefonu
        //   "field[87][]": $("#contact_me").is(":checked") ? "Da" : "Ne",
        //   "field[82][]": $("#ch_email").is(":checked") ? "Da" : "Ne",
        //   "field[84][]": $("#ch_sms").is(":checked") ? "Da" : "Ne",
        //   "field[85][]": $("#ch_phone").is(":checked") ? "Da" : "Ne",
        // };
        // var i = 0;
        // if ($("#ch_email:checked")) {
        //   madata["field[43][" + i + "]"] = "E-pošte";
        //   i++;
        // }
        // else {
        //   madata["field[43][" + i + "]"] = "";
        //   i++;
        // }
        // if ($("#ch_phone:checked")) {
        //   madata["field[43][" + i + "]"] = "Telefona";
        //   i++;
        // } else {
        //   madata["field[43][" + i + "]"] = "";
        //   i++;
        // }
        // if ($("#ch_sms:checked")) {
        //   madata["field[43][" + i + "]"] = "SMS/MMS";
        // } else {
        //   madata["field[43][" + i + "]"] = "";
        //   i++;
        // }

        Form.syncStorage(ajaxdata);

        // var maform = jQuery.param(madata);
        // console.log(maform);
        // const url = $("#_form_47_").attr("action");

        // $("#maform").attr("src", url + "?" + maform);

        // // //	Show complete
        // $("#ds-main-form").hide();
        // $("#ds-form-done").show();
        return false;
      });
  },
  clearStorage: function () {
    return false;
  },
  reload: function () {
    document.getElementById("ds-main-form").reset();
    $("#ds-form-done").hide();
    $("#ds-main-form").show();
    window.scrollTo(0, 0);
    $("#ds-main-form").data("formValidation").resetForm();
    return false;
  },
  changeUsername: function () {
    var username = prompt("Vnesite ime naprave", Form.username);
    if (username != null) {
      Form.username = username;
      Form.storage.set("username", username);
    }
    return false;
  },
  syncStorage: function (ajaxdata) {
    $.ajax({
      dataType: "json",
      type: "POST",
      url: Form.storeURL,
      data: ajaxdata,
      success: function (data) {
        if (data.success === true) {
          $("#ds-main-form").hide();
          $("#ds-form-done").show();
          document.getElementById("ds-form-done").scrollIntoView();
          $(".footer-row").css({
            margin: "0px",
            overflow: "visible",
            width: "100%",
            left: "50%",
            "margin-left": "-50%",
            padding: "0",
            position: "fixed",
            bottom: "0",
            "z-index": "11000",
          });
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        console.log(xhr.responseText);
        console.log(thrownError);

        alert(
          "Prišlo je do napake pri prenosu podatkov v sistem. Prosimo poskusite ponovno."
        );
      },
    });
    return false;
  },
  guid: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  },
  getDealerName: function () {
    var dealerName;
    $.ajax({
      type: "GET",
      url: "/api/dealer-name/" + $("#client").val(),
      success: function (data) {
        if (data.success == true) {
          dealerName = data.name;
        } else {
          dealerName = "";
        }
      },
      error: function () {
        console.warn("And error has occured during get-dealer name call.");
      },
    });

    return dealerName;
  },
};
$(function () {
  Form.run();
});

$(document).on("click", "div.checkbox label", function () {
  var f = $(this).attr("for");
  var c = $('input[type="checkbox"]#' + f).is(":checked") ? 1 : 0;
  if (c > 0) {
    $('input[type="checkbox"]#' + f).prop("checked", true);
  } else {
    $('input[type="checkbox"]#' + f).prop("checked", false);
  }
});

$(document).on("change", "#channels-select input#all_channels", function () {
  if ($(this).is(":checked")) {
    $('.chk-controlled-group input[type="checkbox"]').prop("checked", true);
  } else {
    $('.chk-controlled-group input[type="checkbox"]').prop("checked", false);
  }
});
const controlGroups = function (controller, controlled) {
  $(controlled).prop("disabled", true);
  $(controller).click(function () {
    if ($(this).is(":checked")) {
      $(controlled).not(this).prop("disabled", false);
    } else {
      $(controlled).prop("disabled", true).prop("checked", false);
    }
  });
};
controlGroups(".chk-controller", ".chk-controlled");

var mentionslegales = {
  init: function () {
    $("html").hasClass("touch")
      ? ($(".mlinfobulle .cta, .bulle .trigger-bulle").on(
          "click",
          function (e) {
            e.preventDefault(),
              e.stopImmediatePropagation(),
              "block" === $(this).next().css("display")
                ? mentionslegales.hideml($(this))
                : mentionslegales.showml($(this));
          }
        ),
        $(window).scroll(function (e) {
          e.preventDefault(),
            e.stopImmediatePropagation(),
            mentionslegales.hideml($("p.mlinfobulle"), "all");
        }))
      : ($(".mlinfobulle .cta, .bulle .trigger-bulle").on(
          "mouseover",
          function (e) {
            e.preventDefault(),
              e.stopImmediatePropagation(),
              mentionslegales.showml($(this));
          }
        ),
        $(".mlinfobulle .cta, .bulle .trigger-bulle").on(
          "mouseleave",
          function (that) {
            that.preventDefault(), that.stopImmediatePropagation();
            that = $(this);
            mentionslegales.hideml(that);
          }
        ),
        $(".mlinfobulle.inside-bulle").on("mouseleave", function (e) {
          e.preventDefault(), e.stopImmediatePropagation(), $(this).hide();
        })),
      $(".legalmention .cta").click(function (e) {
        $(this).closest(".legalmention").addClass("priority"),
          mentionslegales.clickml($(this), e);
      });
  },
  showml: function (obj) {
    var occupation =
      obj.parents(".legalmention").offset().left +
      obj.width() +
      obj.next().width();
    ($("body").hasClass("nomarielouise")
      ? $(window).width()
      : $(window).width() - 80) < occupation
      ? (obj
          .next()
          .css(
            "left",
            obj.position().left - obj.next().width() - obj.width() / 2 - 15
          ),
        obj.next().removeClass("arr-left"),
        obj.next().addClass("arr-right"))
      : (obj.next().css("left", obj.position().left + obj.width() / 2 - 25),
        obj.next().removeClass("arr-right"),
        obj.next().addClass("arr-left")),
      obj
        .next()
        .css("bottom", obj.position().top + obj.outerHeight() + 20)
        .show();
  },
  hideml: function (obj, allobj) {
    ($("html").hasClass("touch") && "all" === allobj ? obj : obj.next()).hide();
  },
  clickml: function (obj, evt) {
    obj.parent().hasClass("mlurldirect") || evt.preventDefault();
  },
};
